import React, { Component, Fragment } from 'react';
import GatsbyLink from '../components/GatsbyLink';
import { FaChevronDown } from 'react-icons/fa';
import './HeaderNav.scss'

const isClient = typeof window !== 'undefined';

const enableBodyScroll = () => {
  if (isClient) {
    document.body.classList.remove('nav-open');
  }
}

const NavItem = (props) => {
  const { item, baseUrl, openNavKey, toggleSubNav } = props;
  const {
    slug,
    object_id,
    title,
    url,
    children
  } = item;
  const isOpen = openNavKey === object_id;
  return (
    <span
      key={object_id}
      //className={`link${children ? ' parent' : ''}`}
    >
      <GatsbyLink to={url} onClick={enableBodyScroll} activeClassName="active" aria-label={title} decode={true}>{title}</GatsbyLink>
      {children && children[0].url !== '#gatsby' && (
        <Fragment>
          <NavChildren baseUrl={baseUrl} childNav={children} isOpen={isOpen} />
          <button
            type="button"
            className={`expand ${isOpen ? 'open' : 'closed'}`}
            onClick={event => toggleSubNav(event, object_id)}
            aria-label="Open Menu"
          >
            <FaChevronDown/>
          </button>
        </Fragment>
      )}
    </span>
  );
}

const NavChildren = (props) => {
  const { childNav, baseUrl, isOpen = null } = props;
  return (
    <ul className={`children ${isOpen ? 'open' : 'close'}`}>
      {childNav.map((child, index) => {
        const {
          classes,
          object_id,
          slug,
          title,
          url
        } = child;
        return (
          <li
            key={index}
            >
            <GatsbyLink to={url} onClick={enableBodyScroll} activeClassName="active" aria-label={title} decode={true}>{title}</GatsbyLink>
          </li>
        )
      })}
    </ul>
  );
}

const SERVICES_TYPE = 'services-nav';
const COMMUNCIATIONS_TYPE = 'communication-nav';
const COMPANY_TYPE = 'company-nav';

export default class HeaderNav extends Component {
  state = { openNavKey: null };

  componentDidMount() {
    if (isClient) {
      document.addEventListener('keydown', this.handleEscKey, false);
    }
  }

  componentWillUnmount() {
    if (isClient) {
      document.removeEventListener('keydown', this.handleEscKey, false);
    }
  }

  handleEscKey = (event) => {
    const { toggleNav, active } = this.props;
    if (event.keyCode === 27 && active) toggleNav(event);
  }

  toggleSubNav = (event, openNavKey) => {
    event.preventDefault();
    const { openNavKey: currentOpenNavKey } = this.state;
    if (currentOpenNavKey === openNavKey) return this.setState({ openNavKey: null });
    return this.setState({ openNavKey });
  }

  render() {
    const { active, navItems, baseUrl, className } = this.props;
    const { openNavKey } = this.state;
    return (

        <ul className={className}>
          {navItems && navItems.map((col, index) => index < 10 && (
            <li
              key={index}
            >
              <NavItem key={col.url} item={col} baseUrl={baseUrl} toggleSubNav={this.toggleSubNav} openNavKey={openNavKey} />
            </li>
          ))}
        </ul>

    );
  }
}
