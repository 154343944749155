import React from 'react';
import GatsbyLink from '../src/components/GatsbyLink';
import './Logo.scss';

export const Logo = props => {
  return (
    <GatsbyLink to="/" className="logo">
      <svg width="625.8px" height="259.3px" viewBox="0 0 625.8 259.3">
        <path d="M179.3,88c-8.4-2.2-11.3-0.1-11.3,7.9v3c0,11.9,0.2,12,9.8,11.3c0.1,0,0.4,0,0.4,0c1.3-1.2,3.6-2.4,3.8-3.7
          c0.4-5.1,0.3-10.2,0.1-15.3C182,90,180.5,88.3,179.3,88z M83.4,131.4c-3.8,0.1-5.7,1.3-5.5,5.3c0.2,3.7,0,7.5,0,11.3
          c0,8.4,0,8.4,8.3,8.4c7.6,0,7.8-0.3,7.9-7.8c0-1.6,0-3.2,0-4.8C94.2,131,94.2,131.1,83.4,131.4z M89.5,114.7c3.2,0,4.6-1.3,4.6-4.5
          c0-3.8,0-7.5,0-11.3c0-7.9-0.3-8.2-8.1-8.2c-7.8,0-8.1,0.3-8.1,8.2c0,1.3,0,2.5,0,3.8C78.1,115.6,75.9,114.7,89.5,114.7z
           M583.1,35.5c0,0-116.6-35.5-270.2-35.5S42.7,35.5,42.7,35.5C17.8,43.6,0,54.7,0,78.2v89.4c0,23.5,19.7,36,42.7,42.7l270.2,49
          l270.2-49c22.2-6.3,42.7-19.2,42.7-42.7V78.2C625.8,54.7,608.5,44,583.1,35.5z M106.1,126.6c4.5,2.9,4.9,7,4.8,11.7
          c-0.2,7.5,0,15.1-0.1,22.6c-0.1,5.9-6.2,11.9-12,11.9c-12.7,0-25.4,0-37.9,0V75.1c1.2-0.1,2.8-0.3,4.3-0.3c9.9,0,19.8,0,29.6,0
          c11.6,0,16,4.3,16,16c0,5-0.2,10.1,0.1,15.1c0.3,5.6-0.3,10.6-5.4,14.2C102.2,122.3,103.6,125,106.1,126.6z M139.7,172.9h-16.5V74.4
          H140C139.3,107.6,139.6,139.7,139.7,172.9z M196.1,173h-41.9v-11.6c13.9,0.5,27.7,0.2,41.9,0.2V173z M181.9,152
          c-2-9-3.9-17.7-6.2-26.2c-0.3-1.3-3-2.7-4.6-2.7c-1,0-2.9,2.4-2.9,3.7c-0.3,8.3-0.1,16.6-0.1,25.2h-13.8V75c0.8-0.1,1.9-0.3,3-0.3
          c9.5,0,18.9,0,28.4,0c4.9,0,9.9,4.5,9.9,9.1c0.1,9.9,0.3,19.9-0.1,29.8c-0.1,2.3-2,5.1-3.9,6.7c-2.3,2-2.7,3.7-2,6.3
          c2.3,8.3,4.4,16.7,6.7,25.3H181.9z M260.5,162c0,3.7-6.3,10.7-9.8,10.8c-13.4,0.2-26.8,0.1-40.4,0.1v-98c13.5,0,26.9-0.1,40.3,0.1
          c3.6,0,9.8,7,9.8,10.7C260.6,111.1,260.6,136.5,260.5,162z M317.5,125.9c4.7,3.7,5.6,8.3,5.3,13.8c-0.3,6.4,0.3,12.9-0.2,19.4
          c-0.3,3.1-2,6.1-3.5,8.9c-2.3,4.3-6.3,5.1-11,5c-10-0.3-20.1-0.1-30.1-0.1c-1.7,0-3.5-0.1-5-0.2V75.1c1.2-0.1,2.8-0.3,4.3-0.3
          c9.9,0,19.7,0,29.6,0c11.2,0,15.8,4.6,15.8,15.8c0,4.5-0.3,9,0.1,13.5c0.5,6.5-0.2,12.4-5.9,16.8
          C316.1,121.4,316.5,125.1,317.5,125.9z M379.4,173h-41.9v-11.6c13.9,0.5,27.7,0.2,41.9,0.2V173z M365.2,152c-2-9-3.9-17.7-6.2-26.2
          c-0.3-1.3-3-2.7-4.6-2.7c-1,0-2.9,2.4-2.9,3.7c-0.3,8.3-0.1,16.6-0.1,25.2h-13.8V75c0.8-0.1,1.9-0.3,3-0.3c9.5,0,18.9,0,28.4,0
          c4.9,0,9.9,4.5,9.9,9.1c0.1,9.9,0.3,19.9-0.1,29.8c-0.1,2.3-2,5.1-3.9,6.7c-2.3,2-2.7,3.7-2,6.3c2.3,8.3,4.4,16.7,6.7,25.3H365.2z
           M441.7,173c0,0-15.8,0-16.4,0c0,0,0-4.4,0-11.1l0,0c0-0.1,0-0.3,0-0.4c0-0.5,0-1.1,0-1.6c-0.1-1.7-0.5-2.6-0.7-3.4
          c-2-7.2-6.8-3.3-11-3.5c-2.2-0.1-3.8-0.2-4.4,3.7c-0.1,0.9-0.4,2.4-0.4,4.8h0c0,6.9,0,11.5,0,11.5c-0.7,0-16.4,0-16.4,0
          s-0.1-61.8,0-87.3c0-3.7,6.2-10.7,9.8-10.7c1.5,0,3,0,4.6,0v-0.1c2.7,0,5.3,0,8,0c0.8,0,1.5,0,2.3,0c0.8,0,1.5,0,2.3,0
          c2.7,0,5.3,0,8,0V75c1.5,0,3,0,4.6,0c3.6,0,9.8,7,9.8,10.7C441.8,111.2,441.7,173,441.7,173z M472.2,172.9h-17.2V74.7h17.2V172.9z
           M527.3,172.7c-1.4-0.2-3.1-2.7-3.8-4.5c-6-14.8-11.9-29.7-17.9-44.5c-0.8-1.9-1.7-3.7-2.6-5.5c-0.3,0.2-0.6,0.3-0.9,0.5V173h-17.5
          V74.8c4.6,0,9.8-0.2,14.9,0.2c1.2,0.1,2.7,2,3.2,3.4c6.6,16.1,13,32.2,19.4,48.3c0.4,0.9,1,1.8,2.5,2.5V74.7h17.5l0.1,98.3
          C537.5,172.9,532.4,173.2,527.3,172.7z M574.4,125.5c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.1,0c-13.5,0.1-17.3-11.9-17.3-20.8
          c0-14.4,7.4-29.8,17.5-29.8c0.2,0,0.3,0,0.5,0.1c9.9,0.5,17.1,15.6,17.1,29.7C591.7,113.7,587.9,125.6,574.4,125.5z M362.6,88
          c-8.4-2.2-11.3-0.1-11.3,7.9v3c0,11.9,0.2,12,9.8,11.3c0.1,0,0.4,0,0.4,0c1.3-1.2,3.6-2.4,3.8-3.7c0.4-5.1,0.3-10.2,0.1-15.3
          C365.3,90,363.8,88.3,362.6,88z M419.5,90.9c-0.8,0-1.7,0.1-2.5,0.1c-0.8,0-1.7,0-2.5-0.1c-3.9-0.2-5.6,1.8-5.7,5.5
          c0,1.9,0,19.9,0,38.1c0.1,0.6,0.3,1,0.4,1c2.2,0.5,13.3,0.1,15.5-0.2c0.1,0,0.3-0.4,0.4-0.8c0-18.1,0-36.2,0-38
          C425.2,92.7,423.4,90.7,419.5,90.9z M580.9,94.8h-3.5l2.9-6.3c0,0,0.2-0.3,0.2-0.9s-0.4-1.4-0.9-1.6s-0.6-0.3-1.1-0.4
          c-0.5,0-7-0.1-7.6,0c-0.6,0.1-1.1,0.6-1.3,1.2l-4.2,14.8c-0.1,0.2-0.1,0.5-0.1,0.8c0,1.2,0.9,2.2,2,2.2h3.4l-3.6,10.6l-0.1,0.4
          c-0.1,0.7,0.1,1.3,0.5,1.8c0.4,0.4,1.3,0.9,2.2,0.3c2.7-1.7,12.6-19.9,12.6-19.9c0.2-0.3,0.4-0.7,0.4-1.1
          C582.7,95.6,581.9,94.8,580.9,94.8z M294.5,131.4c-3.4,0.3-4.8,1.7-4.8,5.1c0.1,4.8,0,9.7,0,14.5c0,3.5,1.4,5.2,5.1,5.4
          c11,0.7,11.4,0.4,11.4-10.8V144C306.3,130.6,306.1,130.5,294.5,131.4z M238.3,90.8c-1.6,0.1-3.2,0.1-4.8,0c-4.5-0.3-6,1.9-5.9,6.2
          c0.2,8.8,0.1,17.6,0.1,26.4c0,8.6,0,17.2,0,25.8c0,6.9,0.3,7.2,7.3,7.2c1.3,0,2.5-0.1,3.8,0c3.7,0.2,5.2-1.8,5.2-5.2
          c0-18.3,0-36.6,0-54.9C244,92.5,242.2,90.6,238.3,90.8z M306.3,102.9c0-1.4,0-2.9,0-4.3c0-7.2-0.5-7.7-7.6-7.8c-1.3,0-2.5,0-3.8,0
          c-3.3,0-5.1,1.5-5.1,4.8c-0.1,5-0.3,10.1,0.2,15c0.1,1.4,2.5,3.8,3.9,3.8c13.9,0.4,12.4,1.1,12.4-10.6V102.9z"></path></svg>
    </GatsbyLink>
  );
}
